.video-wrapper:hover .btn {
    opacity: 1;
}

.video-wrapper .btn-pause {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.mobile-nav {
    .nav-link {
        display: flex !important;
        border-bottom: 1px solid lightgrey !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}